import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

function MyCoolCodeBlock({ code, language, showLineNumbers }) {
    return (
        <CodeBlock
            text={code}
            language={language}
            showLineNumbers={showLineNumbers}
            theme={vs2015}
        />
    );
}

const articleData = {
    id: 17,
    title: "React nell’IoT:",
    desc: "Creare Interfacce Utente Intuitive per Dispositivi Connessi",
    img: "/blog-image/IoT.jpg",
    page: "blog/react-IoT",
    data: "18 Set 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "Negli ultimi anni, l'Internet of Things ha guadagnato una posizione di rilievo nella nostra vita quotidiana.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        Negli ultimi anni, l'Internet of Things
                                        ha guadagnato una posizione di rilievo
                                        nella nostra vita quotidiana.
                                        Dall'automazione domestica all'industria
                                        manifatturiera, i dispositivi connessi
                                        stanno diventando sempre più comuni. Ma
                                        come possiamo creare interfacce utente
                                        intuitive per questi dispositivi? In
                                        questo articolo, esploreremo come
                                        utilizzare React per sviluppare
                                        interfacce utente per dispositivi IoT.
                                    </p>

                                    <h3>
                                        Creazione di Interfacce Utente React per
                                        IoT
                                    </h3>
                                    <h5>Setup Iniziale</h5>
                                    <p>
                                        Per iniziare, è necessario configurare
                                        l'ambiente di sviluppo React.
                                        Utilizziamo npm (Node Package Manager)
                                        per installare le dipendenze necessarie
                                        e avviare il progetto React. Ecco come
                                        farlo:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
// Creare un nuovo progetto React
npx create-react-app iot-interface
        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <br />
                                    <br />
                                    <MyCoolCodeBlock
                                        code={`
// Accedere alla directory del progetto
cd iot-interface
                                            `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <br />
                                    <br />
                                    <MyCoolCodeBlock
                                        code={`
// Avviare il server di sviluppo
npm start
                                            `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <p>
                                        Una volta completata questa procedura,
                                        l’ambiente di sviluppo React sarà pronto
                                        per l'uso.
                                    </p>
                                    <h5>Creazione di Componenti</h5>
                                    <p>
                                        Il cuore di React è la creazione di
                                        componenti riutilizzabili. Per
                                        un'applicazione IoT, è possibile creare
                                        componenti che rappresentano diverse
                                        parti del dispositivo connesso - come
                                        sensori, display e controlli. Ad
                                        esempio, puoi creare un componente
                                        "Sensor" che visualizza i dati del
                                        sensore e un componente "Control" per
                                        interagire con il dispositivo.
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
// Esempio di componente Sensor
import React from 'react';

const Sensor = ({ data }) => {
    return (
        <div>
            <h2>Sensor Data</h2>
            <p>Temperature: {data.temperature} °C</p>
            <p>Humidity: {data.humidity} %</p>
        </div>
    );
};
export default Sensor;

// Esempio di componente Control
import React from 'react';

const Control = ({ onButtonClick }) => {
    return (
        <div>
            <h2>Device Control</h2>
            <button onClick={onButtonClick}>Toggle Device</button>
        </div>
    );
};

export default Control; 
                                            `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <h5>Comunicazione con Dispositivi IoT</h5>
                                    <p>
                                        Per creare un'interfaccia utente per
                                        dispositivi IoT, è fondamentale
                                        stabilire una comunicazione
                                        bidirezionale con il dispositivo. Si
                                        possono utilizzare librerie come MQTT.js
                                        o WebSocket per gestire questa
                                        comunicazione. Ecco un esempio di
                                        utilizzo di MQTT.js in un componente
                                        React:
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
import React, { useState, useEffect } from 'react';
import mqtt from 'mqtt';

const Sensor = () => {
    const [data, setData] = useState({ temperature: 0, humidity: 0 });
    
    useEffect(() => {
        const client = mqtt.connect('mqtt://broker.example.com');
    
        client.on('connect', () => {
            client.subscribe('sensor/data');
        });
    
        client.on('message', (topic, message) => {
            const parsedData = JSON.parse(message.toString());
            setData(parsedData);
        });
    
        return () => {
            client.end();
        };
    }, []);

    return (
        <div>
            <h2>Sensor Data</h2>
        <p>Temperature: {data.temperature} °C</p>
        <p>Humidity: {data.humidity} %</p>
        </div>
    );
};

export default Sensor; 
        `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h5>Gestione degli Stati e degli Eventi</h5>
                                    <p>
                                        Nel contesto IoT, è essenziale gestire
                                        gli stati dei dispositivi e rispondere
                                        agli eventi in modo efficace. React
                                        offre un sistema di gestione degli stati
                                        tramite il hook `useState` e la
                                        possibilità di gestire gli eventi con la
                                        sintassi JSX.
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
import React, { useState } from 'react';

const Control = () => {
    const [deviceState, setDeviceState] = useState('off');

    const toggleDevice = () => {
        const newState = deviceState === 'off' ? 'on' : 'off';
        setDeviceState(newState);

        // Invia il comando al dispositivo tramite MQTT o WebSocket
    };

    return (
        <div>
            <h2>Device Control</h2>
            <p>Device State: {deviceState}</p>
            <button onClick={toggleDevice}>Toggle Device</button>
        </div>
    );
};

export default Control; 
                                    `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h5>Stili e CSS</h5>
                                    <p>
                                        Per migliorare l'aspetto delle
                                        interfacce utente per dispositivi IoT,
                                        si può utilizzare CSS e librerie di
                                        stile come Bootstrap o Material-UI.
                                        L’integrazione di stili all’interno del
                                        progetto React non è complesso,
                                        consentendo di personalizzare l'aspetto
                                        dei componenti.
                                    </p>
                                    <MyCoolCodeBlock
                                        code={`
// Importa un file CSS di stile
import './Sensor.css';

/* Sensor.css */
.Sensor {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    padding: 20px;
} 
                                    `}
                                        language="javascript"
                                        showLineNumbers={true}
                                    />
                                    <h5>Test e Debugging</h5>
                                    <p>
                                        Infine, è necessario assicurarci di
                                        testare e debuggare le interfacce utente
                                        React per il nostro dispositivo IoT. Per
                                        ispezionare i componenti, monitorare lo
                                        stato dell'applicazione e individuare
                                        eventuali errori, si possono utilizzare
                                        strumenti come React DevTools. Ma di
                                        questo parleremo in un altro articolo.
                                    </p>
                                    <p className="p-3">
                                        Buono sviluppo a tutti.
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};
export default BlogDetails;
